:root {
    --loading-grey: #f5f5f5;
  }
  
  .card {
    border-radius: 6px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, .12);
    padding-top: 0.5rem;
  }

  .loading {
    background-color: #f5f5f5;
    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, .5) 50%,
      rgba(255, 255, 255, 0) 60%
    ) var(--loading-grey);
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }
  
  @keyframes loading {
    to {
      background-position-x: -20%;
    }
  }
  
  .loading {
    animation-delay: .05s;
    border-radius: 4px;
    min-height: 1.1rem;
  }

  .skeleton {
    opacity: .7;
    animation: skeleton-loading 1s linear infinite alternate;
  }

  @keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}