.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  0% {
    color: black;
  }
  50% {
    color: lightblue;
  }
}